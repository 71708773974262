import { default as indexI3WLUFOzptMeta } from "/var/home/archerist/nuxt3_ogrenci/pages/archive/index.vue?macro=true";
import { default as emailU8sCoZH4zhMeta } from "/var/home/archerist/nuxt3_ogrenci/pages/email.vue?macro=true";
import { default as forcepasschange24mDaleu7OMeta } from "/var/home/archerist/nuxt3_ogrenci/pages/forcepasschange.vue?macro=true";
import { default as indexsj68kIV1H4Meta } from "/var/home/archerist/nuxt3_ogrenci/pages/index.vue?macro=true";
import { default as citiesNWFK0Dx07tMeta } from "/var/home/archerist/nuxt3_ogrenci/pages/reports/cities.vue?macro=true";
import { default as daterangetHeVKu4c8DMeta } from "/var/home/archerist/nuxt3_ogrenci/pages/reports/daterange.vue?macro=true";
import { default as fakultelNJkP4FcvKMeta } from "/var/home/archerist/nuxt3_ogrenci/pages/reports/fakulte.vue?macro=true";
import { default as indexEgIDcRKfHzMeta } from "/var/home/archerist/nuxt3_ogrenci/pages/reports/index.vue?macro=true";
import { default as newmembersjwbeaERKeXMeta } from "/var/home/archerist/nuxt3_ogrenci/pages/reports/newmembers.vue?macro=true";
import { default as ogrenciseviyeHe4H3zrCfxMeta } from "/var/home/archerist/nuxt3_ogrenci/pages/reports/ogrenciseviye.vue?macro=true";
import { default as periodicKiaAIvrcBEMeta } from "/var/home/archerist/nuxt3_ogrenci/pages/reports/periodic.vue?macro=true";
import { default as universities0cnbiuBpv0Meta } from "/var/home/archerist/nuxt3_ogrenci/pages/reports/universities.vue?macro=true";
import { default as indexWiJtsuJSKpMeta } from "/var/home/archerist/nuxt3_ogrenci/pages/settings/index.vue?macro=true";
import { default as emailsOKV7AjMZnJMeta } from "/var/home/archerist/nuxt3_ogrenci/pages/students/[username]/emails.vue?macro=true";
import { default as family9dodGRZWLDMeta } from "/var/home/archerist/nuxt3_ogrenci/pages/students/[username]/family.vue?macro=true";
import { default as idE6UookQ0yjMeta } from "/var/home/archerist/nuxt3_ogrenci/pages/students/[username]/id.vue?macro=true";
import { default as legalt1p0c7lTK3Meta } from "/var/home/archerist/nuxt3_ogrenci/pages/students/[username]/legal.vue?macro=true";
import { default as paymentsWYOmACzFumMeta } from "/var/home/archerist/nuxt3_ogrenci/pages/students/[username]/payments.vue?macro=true";
import { default as schoolQ9mHnn6nIDMeta } from "/var/home/archerist/nuxt3_ogrenci/pages/students/[username]/school.vue?macro=true";
import { default as settingsGYWJwol02TMeta } from "/var/home/archerist/nuxt3_ogrenci/pages/students/[username]/settings.vue?macro=true";
import { default as indexozG6uvCZu4Meta } from "/var/home/archerist/nuxt3_ogrenci/pages/students/index.vue?macro=true";
export default [
  {
    name: "archive",
    path: "/archive",
    component: () => import("/var/home/archerist/nuxt3_ogrenci/pages/archive/index.vue")
  },
  {
    name: "email",
    path: "/email",
    component: () => import("/var/home/archerist/nuxt3_ogrenci/pages/email.vue")
  },
  {
    name: "forcepasschange",
    path: "/forcepasschange",
    meta: forcepasschange24mDaleu7OMeta || {},
    component: () => import("/var/home/archerist/nuxt3_ogrenci/pages/forcepasschange.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexsj68kIV1H4Meta || {},
    component: () => import("/var/home/archerist/nuxt3_ogrenci/pages/index.vue")
  },
  {
    name: "reports-cities",
    path: "/reports/cities",
    component: () => import("/var/home/archerist/nuxt3_ogrenci/pages/reports/cities.vue")
  },
  {
    name: "reports-daterange",
    path: "/reports/daterange",
    component: () => import("/var/home/archerist/nuxt3_ogrenci/pages/reports/daterange.vue")
  },
  {
    name: "reports-fakulte",
    path: "/reports/fakulte",
    component: () => import("/var/home/archerist/nuxt3_ogrenci/pages/reports/fakulte.vue")
  },
  {
    name: "reports",
    path: "/reports",
    component: () => import("/var/home/archerist/nuxt3_ogrenci/pages/reports/index.vue")
  },
  {
    name: "reports-newmembers",
    path: "/reports/newmembers",
    component: () => import("/var/home/archerist/nuxt3_ogrenci/pages/reports/newmembers.vue")
  },
  {
    name: "reports-ogrenciseviye",
    path: "/reports/ogrenciseviye",
    component: () => import("/var/home/archerist/nuxt3_ogrenci/pages/reports/ogrenciseviye.vue")
  },
  {
    name: "reports-periodic",
    path: "/reports/periodic",
    component: () => import("/var/home/archerist/nuxt3_ogrenci/pages/reports/periodic.vue")
  },
  {
    name: "reports-universities",
    path: "/reports/universities",
    component: () => import("/var/home/archerist/nuxt3_ogrenci/pages/reports/universities.vue")
  },
  {
    name: "settings",
    path: "/settings",
    component: () => import("/var/home/archerist/nuxt3_ogrenci/pages/settings/index.vue")
  },
  {
    name: "students-username-emails",
    path: "/students/:username()/emails",
    component: () => import("/var/home/archerist/nuxt3_ogrenci/pages/students/[username]/emails.vue")
  },
  {
    name: "students-username-family",
    path: "/students/:username()/family",
    component: () => import("/var/home/archerist/nuxt3_ogrenci/pages/students/[username]/family.vue")
  },
  {
    name: "students-username-id",
    path: "/students/:username()/id",
    component: () => import("/var/home/archerist/nuxt3_ogrenci/pages/students/[username]/id.vue")
  },
  {
    name: "students-username-legal",
    path: "/students/:username()/legal",
    component: () => import("/var/home/archerist/nuxt3_ogrenci/pages/students/[username]/legal.vue")
  },
  {
    name: "students-username-payments",
    path: "/students/:username()/payments",
    component: () => import("/var/home/archerist/nuxt3_ogrenci/pages/students/[username]/payments.vue")
  },
  {
    name: "students-username-school",
    path: "/students/:username()/school",
    component: () => import("/var/home/archerist/nuxt3_ogrenci/pages/students/[username]/school.vue")
  },
  {
    name: "students-username-settings",
    path: "/students/:username()/settings",
    component: () => import("/var/home/archerist/nuxt3_ogrenci/pages/students/[username]/settings.vue")
  },
  {
    name: "students",
    path: "/students",
    component: () => import("/var/home/archerist/nuxt3_ogrenci/pages/students/index.vue")
  }
]